import React, { useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Img,
  Avatar,
  AvatarBadge,
  Button,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import dayjs from 'dayjs';
import useModalPaddings from 'src/hooks/useModalPaddings';
import StarRating from '../StarRating';
import NextLink from '../NextLink';
import { extractImage } from '../../utils/extractImage';
import { isOnline } from '../../utils/getLastVisit';
import Modal from '../Modal';
import { dateFormat, imageSizes } from '../../common/constants';

const styleContainer = {
  mt: [0, 0, 8],
  mx: [0, 0, 2],
  pos: 'relative',
  bg: 'white',
  borderRadius: 'xl',
  p: 4,
};

const styleFeedbackHeader = {
  w: '100%',
  flexGrow: 1,
  alignItems: 'flex-end',
};

const styleStarRatingContainer = {
  w: 44,
  mt: 2,
  mb: 2.5,
  pos: 'absolute',
  top: -6,
  right: 4,
};

const styleDateIcon = {
  boxSize: 5,
  mr: 2,
  src: '/icons/calendar.svg',
};

const styleDate = {
  as: 'span',
  fontWeight: 'normal',
  color: 'typography.secondary',
};

const styleSpan = {
  as: 'span',
  color: 'typography.secondary',
};

const styleAvatarBadge = {
  boxSize: 3,
  bg: 'green.500',
  right: 0.5,
  bottom: 0.5,
};

const styleAvatar = user => ({
  alt: user?.name,
  size: 'full',
  boxSize: 11.5,
  mr: 5,
});

const styleFeedbackBody = {
  w: '100%',
  mt: 2,
};

const styleOrderTitle = {
  as: 'div',
  mb: 2.5,
  color: 'typography.secondary',
};

const styleFeedbackText = isShow => ({
  fontWeight: 'normal',
  mb: 2.5,
  maxH: isShow ? 'auto' : 100,
});

const styleUserNameFrom = {
  as: 'div',
  my: 2,
};

const styleUserNameFor = {
  as: 'div',
  mt: 2,
  fontWeight: 'normal',
  textAlign: 'left',
};

const PreviewFeedback = ({ review, ...other }) => {
  const { t } = useTranslation('common');
  const [isShow, setIsShow] = useState(false);
  const sliceIndex = review?.text.slice(0, 200)?.lastIndexOf(' ');
  const boxPaddings = useModalPaddings()
  return (
    <Box key={review.id} {...styleContainer} {...other}>
      <Modal isOpen={isShow} onClose={() => setIsShow(false)}>
        <Text {...boxPaddings}>{review?.text}</Text>
      </Modal>
      <Box {...styleStarRatingContainer}>
        <StarRating
          isShadow={true}
          isMobile={false}
          rating={review.rating ? review.rating : 0}
          spacing={1}
        />
      </Box>
      <NextLink href={`/executors/${review?.for?.id}`}>
        <Flex data-testid="link-to-executor" {...styleFeedbackHeader}>
          <Avatar
            {...styleAvatar(review?.for)}
            src={extractImage(review?.for?.image, imageSizes.S)}
          >
            {isOnline(review?.for?.lastVisit) && (
              <AvatarBadge {...styleAvatarBadge} />
            )}
          </Avatar>
          <Text {...styleUserNameFor}>
            {review?.for?.name} {review?.for?.lastName}
          </Text>
        </Flex>
      </NextLink>
      <Box {...styleFeedbackBody}>
        <Text {...styleOrderTitle}>{review?.orderTitle}</Text>
        <Text {...styleFeedbackText} as='div'>
          {review?.text.length <= 200
            ? review?.text
            : review?.text.slice(0, sliceIndex)}
          {review?.text.length > 200 && '...'}
          {review?.text.length > 200 && (
            <Button variant='link' onClick={() => setIsShow(true)}>
              {t('preview-service-feedback.more')}
            </Button>
          )}
        </Text>
        <Text {...styleUserNameFrom}>
          <Text {...styleSpan}>
            {`${t('preview-service-feedback.feedback-from')} `}
          </Text>
          {`${review?.from?.name} ${review?.from?.lastName}`}
        </Text>
      </Box>
      <Flex>
        <Img {...styleDateIcon} />
        <Text {...styleDate}>
          {dayjs(parseInt(review?.createdAt, 10)).format(dateFormat.DMY)}
        </Text>
      </Flex>
    </Box>
  );
};

PreviewFeedback.propTypes = {
  review: PropTypes.object,
  isType: PropTypes.bool,
  router: PropTypes.object,
};

export default PreviewFeedback;
