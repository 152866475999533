import React from 'react';
import { Img, Flex, Text, Box, HStack } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const StarRating = ({ isMobile, rating, isShadow, spacing = 0 }) => {
  const numbers = [1, 2, 3, 4, 5];
  return isMobile ? (
    <Flex data-testid="executor-stars">
      <Img src='/icons/Star.svg' h='16px' mt='3px' mr='5px' />
      <Text fontSize='sm' fontWeight='normal' color='brand.primary'>
        {rating}
      </Text>
    </Flex>
  ) : (
    <HStack position='relative' spacing={spacing}>
      {numbers.map(e => (
        <Box key={e}>
          <svg
            width='100%'
            height='100%'
            viewBox='0 0 39 37'
            filter={isShadow && 'drop-shadow(1px 2px 6px rgb(0 0 0 / 0.2))'}
            fill={e <= Math.round(rating) ? '#439EC2BB' : '#E1EEFCBB'}
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M38.209 14.5977C38.4001 13.6425 37.6359 12.4962 36.6807
          12.4962L25.7911 10.9678L20.8239 1.03347C20.6329 0.651377 20.4418
          0.460331 20.0598 0.269286C19.1045 -0.30385 17.9583 0.0782408 17.3851
          1.03347L12.609 10.9678L1.71941 12.4962C1.14627 12.4962 0.764181
          12.6872 0.573136 13.0693C-0.191045 13.8335 -0.191045 14.9798
          0.573136 15.744L8.40599 23.3858L6.49554 34.2753C6.49554 34.6574
          6.49554 35.0395 6.68658 35.4216C7.25972 36.3768 8.40599 36.7589
          9.36122 36.1858L19.1045 31.0276L28.8478 36.1858C29.0389 36.3768
          29.421 36.3768 29.8031 36.3768C29.9941 36.3768 29.9941 36.3768
          30.1851 36.3768C31.1404 36.1858 31.9046 35.2306 31.7135
          34.0843L29.8031 23.1947L37.6359 15.5529C38.018 15.3619
          38.209 14.9798 38.209 14.5977Z'
            />
          </svg>
        </Box>
      ))}
    </HStack>
  );
};

StarRating.propTypes = {
  rating: PropTypes.number,
  isMobile: PropTypes.bool,
};

export default StarRating;
